import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Play from '../../../static/images/play.svg'

const UpdateVideoPlayButton = styled(Play)`
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 3rem;
    height: 3rem;
  }
`

const UpdatesVideo = ({ children }) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "praise.png" }) {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Multiple Background Array
  const ImageStack = [
    image.childImageSharp.fluid,
    // `linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0))`,
  ]

  return (
    <StyledStackedBackgrounds
      Tag="div"
      fluid={ImageStack}
      id="imagestack"
      role="img"
      aria-label="homepage updates preview image"
      style={{
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <OpacityContainer to={`/news/video`}>
        <UpdateVideoTitleContainer>
          <UpdateVideoTitle>{`클릭하여 영상 더보기`}</UpdateVideoTitle>
        </UpdateVideoTitleContainer>
        <UpdateVideoPlayButton />
      </OpacityContainer>
    </StyledStackedBackgrounds>
  )
}

const OpacityContainer = styled(Link)`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.3);
  text-decoration: none;
`
const UpdateVideoTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 40%;
`
const StyledStackedBackgrounds = styled(BackgroundImage)`
  display: flex;
  width: 100%;
  height: 100%;
`
const UpdateVideoTitle = styled.div`
  color: ${props => props.theme.colors.background};
  word-break: keep-all;
  font-size: 1rem;
  line-height: 1rem;
  padding-top: 1rem;
`

export default UpdatesVideo
