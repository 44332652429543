import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Hero from '../components/Homepage/HeroHome'
import Banner from '../components/Homepage/Banner'
import Services from '../components/Homepage/1__Services'
import Sermons from '../components/Homepage/2__Sermons'
import Fellowship from '../components/Homepage/3__Fellowship'
import Events from '../components/Homepage/4__Events'
import Updates from '../components/Homepage/5__Updates'

const HomeTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={'죠이플 교회'}
        description={
          '북가주 죠이플 교회에 오신 걸 환영합니다!'
        }
      />
      <Hero
        title={"title"}
        image={'heroImage'}
        titleDesc={'titleDesc'}
        height={'75vh'}
      />
      <Banner />
      <Container>
        <Services />
      </Container>
      <Container wideWidth>
        <Sermons />
      </Container>
      <Container wideWidth>
        <Fellowship />
      </Container>
      {/* <Container wideWidth>
        <Events />
      </Container> */}
      <Container>
        <Updates />
      </Container>
    </Layout>
  )
}

export default HomeTemplate
