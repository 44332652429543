import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import StyledBackgroundSection from './BackgroundSection'

const Wrapper = styled.section`
  /* height prop is not used */
  display: flex;
  /* z-index: 99; */
  position: relative;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 0;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.h1`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem;
  color: #ffffff;
  font-family: 'Apple SD Gothic Neo';
  font-size: 3rem;
  /* Responsive font size using vw */
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 8vw;
    line-height: 10vw;
  }

  font-weight: 300;
  letter-spacing: 0;
  line-height: 3.75rem;
  text-align: center;
  word-break: keep-all;
`
const TitleDescription = styled.h2`
  z-index: 2;
  width: 100%;
  padding: 1rem;
  color: #ffffff;
  font-family: 'Apple SD Gothic Neo';
  font-size: 1.25rem;
  /* Responsive font size using vw */
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 4vw;
    line-height: 6vw;
  }
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  word-break: keep-all;
`
const ResponsiveBrMd = styled.br`
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`
const ResponsiveBrLg = styled.br`
  display: none;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`
const ButtonPrimary = styled.button`
  z-index: 2;
  width: 9.25rem;
  height: 2.5rem;
  padding: 0 1rem;
  color: #ffffff;
  background-color: ${props => props.theme.colors.green};
  border-radius: 1.5rem;
  border: 2px solid ${props => props.theme.colors.green};
  /* For iPhone 5/SE */
  @media screen and (max-width: 350px) {
    width: 7rem;
    height: 2rem;
    padding: 0 0.75rem;
  }
  &:hover {
    background-color: rgb(67 182 73 / 70%);
  }
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-right: 1.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    margin-right: 1.25rem;
  }
`
const ButtonSecondary = styled.button`
  z-index: 2;
  width: 9.25rem;
  height: 2.5rem;
  padding: 0 1rem;
  color: ${props => props.theme.colors.yellow};
  border-radius: 1.5rem;
  border: 2px solid ${props => props.theme.colors.yellow};
  /* For iPhone 5/SE */
  @media screen and (max-width: 350px) {
    width: 7rem;
    height: 2rem;
    padding: 0 0.75rem;
  }
  &:hover {
    background-color: rgb(255 193 81 / 30%);
  }
  transition: background-color 0.3s ease;
  cursor: pointer;
`
const TextLink = styled(Link)`
  margin-right: auto;
  text-decoration: none;
  color: ${props => props.theme.colors.yellow};
  font-weight: 400;
`
const TextLinkExternal = styled.a`
  /* border: 1px dotted #ccc; */
  margin-right: auto;
  text-decoration: none;
  color: ${props => props.theme.colors[props.fontColor]};
  font-weight: 600;
  padding: 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0.5rem;
  }
`

const Hero = props => (
  <Wrapper height={props.height}>
    <StyledBackgroundSection>
      <ContentContainer>
        <TextContainer>
          <TitleDescription>
            죠이플 교회에 오신걸 환영합니다! <ResponsiveBrMd />
            매주 주일 오후 1:30에 주일예배가 있습니다.
          </TitleDescription>
          <Title>
            주일예배에 <ResponsiveBrMd />
            현장과 온라인으로{` `}
            <ResponsiveBrLg />
            <ResponsiveBrMd />
            참여하실 수 있습니다.
          </Title>
        </TextContainer>
        <ButtonsContainer>
          <Link to="/info/about-us/">
            <ButtonPrimary>교회소개</ButtonPrimary>
          </Link>
          <Link to="/online-service/">
            <ButtonSecondary>온라인 예배</ButtonSecondary>
          </Link>
        </ButtonsContainer>
        <TextContainer>{``}</TextContainer>
      </ContentContainer>
    </StyledBackgroundSection>
  </Wrapper>
)

export default Hero
