import React from 'react'
// import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'

const StyledUpdatesWindowTable = styled.table`
  width: 100%;
  min-height: 14rem;
  table-layout: fixed;
  & > thead {
    font-size: 90%;
    font-weight: 500;
    line-height: 1.5rem;
    border-bottom: 1px solid ${props => props.theme.colors.text};
    & > tr > th:last-child {
      width: 6rem;
    }
  }
  & > tbody {
    & > tr {
      border-bottom: ${props => props.theme.colors.grey} 1px solid;
    }
    & > tr:last-child {
      border-bottom: none;
    }
    & > tr > td:first-child {
      color: ${props => props.theme.colors.green};
      font-size: 85%;
      font-weight: 500;
      line-height: 1rem;
      vertical-align: middle;
      padding: 0.25rem 0 0.25rem 0.25rem;
    }
    & > tr > td:last-child {
      color: ${props => props.theme.colors.text};
      font-size: 80%;
      font-weight: 300;
      line-height: 1rem;
      vertical-align: middle;
      padding: 1rem 0 1rem 1.25rem;
    }
  }
  & > tfoot {
    border-top: 1px solid ${props => props.theme.colors.text};
  }
`

const UpdatesVideo = props => (
  <div>
    <StyledUpdatesWindowTable>
      <thead>
        <tr>
          <th>Title</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* Title string should be kept at 30 chars or less */}
          <td>게시판을 준비 중입니다.</td>
          <td>2022.01.01</td>
        </tr>
        <tr>
          <td>게시판을 준비 중입니다.</td>
          <td>2022.01.01</td>
        </tr>
        <tr>
          <td>게시판을 준비 중입니다.</td>
          <td>2022.01.01</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </StyledUpdatesWindowTable>
  </div>
)

export default UpdatesVideo
