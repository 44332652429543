import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const UpdatesImage = ({ children }) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "updates_image.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Multiple Background Array
  const ImageStack = [
    image.childImageSharp.fluid,
    // `linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0))`,
  ]

  return (
    <StyledStackedBackgrounds
      Tag="div"
      fluid={ImageStack}
      id="imagestack"
      role="img"
      aria-label="homepage updates preview image"
      style={{
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <OpacityContainer to={`/news/photo`}>
        <ImageTitle>{`주일예배 사진첩`}</ImageTitle>
      </OpacityContainer>
    </StyledStackedBackgrounds>
  )
}

const OpacityContainer = styled(Link)`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.3);
  text-decoration: none;
`
const StyledStackedBackgrounds = styled(BackgroundImage)`
  display: flex;
  width: 100%;
  height: 100%;
  /* @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    max-width: 26rem;
    height: 13rem;
  } */
`
const ImageTitle = styled.div`
  color: ${props => props.theme.colors.background};
  word-break: keep-all;
  font-size: 1.25rem;
  line-height: 1.75rem;
`

export default UpdatesImage
