import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import UpdatesWindow from './UpdatesWindow'
import UpdatesVideo from './UpdatesVideo'
import UpdatesImage from './UpdatesImage'

const UpdatesOuterContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 70rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin-top: -1rem;
    flex-wrap: initial;
  }
`
const UpdateSectionTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 58rem;
`
const SectionTitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  flex: 1;
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  @media screen and (max-width: 1008px) {
    margin-left: initial;
  }
`
const ViewMoreButton = styled(Link)`
  /* border: 1px dotted #ccc; */
  display: flex;
  /* justify-content: flex-end; */
  align-self: flex-end;
  margin-right: 1rem;
  color: ${props => props.theme.colors.orange};
  text-decoration: none;
  font-size: 90%;
  font-weight: 600;
  @media screen and (max-width: 1008px) {
    font-size: 80%;
  }
`
const UpdatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
  @media screen and (max-width: 1008px) {
    flex-direction: column;
    width: 100%;
    max-width: 30rem;
  }
`
const UpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const UpdateTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 18rem;
  margin: 0 auto;
  padding-top: 2rem;
  font-size: 18px;
  color: ${props => props.theme.colors.text};
`
const UpdateItemContainer = styled.div`
  border: ${props =>
    props.bordered ? `3px solid ${props.theme.colors.green}` : `none`};
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  width: 18rem;
  height: 14rem;
  margin: 1rem;
  @media screen and (max-width: 1008px) {
    width: 100%;
    margin: 1rem 0;
    height: ${props => (props.fluid ? `100%` : `14rem;`)};
  }
`
const BoldSpan = styled.span`
  font-weight: 600;
`
const SectionDivider = styled.hr`
  width: 90vw;
  max-width: 58rem;
  margin-bottom: 3.5rem;
  border-top: 1px solid #f3f3f3;
  border-radius: 1px;
  margin-top: -1rem;
  @media screen and (max-width: 1008px) {
    max-width: 28rem;
  }
`

const Events = props => (
  <>
    <SectionDivider />
    <UpdatesOuterContainer>
      <UpdateSectionTitleContainer>
        <SectionTitleSpan>Joyful 소식</SectionTitleSpan>
      </UpdateSectionTitleContainer>
      <UpdatesContainer>
        {/* <UpdateContainer>
          <UpdateTitleContainer>
            <BoldSpan>죠이플 창(窓)</BoldSpan>
          </UpdateTitleContainer>
          <UpdateItemContainer fluid>
            <UpdatesWindow />
          </UpdateItemContainer>
          <ViewMoreButton to={`/news/column`} topRow>{`더 보기 >`}</ViewMoreButton>
        </UpdateContainer> */}
        <UpdateContainer>
          <UpdateTitleContainer>
            <BoldSpan>특별영상</BoldSpan>
          </UpdateTitleContainer>
          <UpdateItemContainer>
            <UpdatesVideo />
          </UpdateItemContainer>
          <ViewMoreButton to={`/news/video`} topRow>{`영상 전체보기 >`}</ViewMoreButton>
        </UpdateContainer>
        <UpdateContainer>
          <UpdateTitleContainer>
            <BoldSpan>최근 앨범</BoldSpan>
          </UpdateTitleContainer>
          <UpdateItemContainer bordered>
            <UpdatesImage />
          </UpdateItemContainer>
          <ViewMoreButton to={`/news/photo`} topRow>{`앨범 전체보기 >`}</ViewMoreButton>
        </UpdateContainer>
      </UpdatesContainer>
    </UpdatesOuterContainer>
  </>
)

export default Events
