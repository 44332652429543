import { React, Fragment } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player/lazy'
import StyledSermonBackground from './SermonBackground'

const SermonsContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 70rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    flex-direction: column;
  }
`
const ServiceDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    flex-flow: wrap;
  }
`
const ServiceDetailVideoContainer = styled.div`
  display: flex;
  width: 38rem;
  margin-left: 2rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 100%;
    margin-left: initial;
  }
`
const DescriptionDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 100%;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    align-items: center;
  }
`
const DescriptionDetailTitle = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 1.5rem;
  margin-top: 0.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
`
const DescriptionDetailTitleMobile = styled.div`
  display: none;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.colors.text};
  font-family: 'Apple SD Gothic Neo';
  font-size: 2rem;
  font-weight: 300;
  padding-bottom: 1.5rem;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: flex;
  }
`
const DescriptionTitleFont = { fontWeight: 'bold', marginRight: 'auto' }

const DescriptionTitleFontMobile = { fontWeight: 'bold' }

const BoldSpan = styled.span`
  font-weight: 600;
`
const SermonVideoItemContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`
const SermonVideoTitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding-bottom: 0.25rem;
`
const SermonVideoDescSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  line-height: 1.5rem;
  padding: 0.25rem 0;
  font-size: 100%;
`
const SermonVideoDescDivider = styled.span`
  display: inline-flex;
  margin: 0 0.75rem;
}
`
const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`
const PlayerWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 */
`
const TextLink = styled(Link)`
  margin-right: auto;
  text-decoration: none;
  color: ${props =>
    props.primary ? props.theme.colors.green : props.theme.colors.orange};
  font-weight: 600;
  padding: 1rem 0;
`
const Sermons = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulWeeklySermonUpdate {
            edges {
              node {
                id
                createdAt
                weeklySermonDate
                weeklySermonTitle
                weeklySermonSeriesName
                weeklySermonSeriesSlug
                weeklySermonSpeaker
                weeklySermonPassage
                weeklySermonVideoId
              }
            }
          }
        }
      `}
      render={data => {
        const { weeklySermonTitle, weeklySermonSeriesName, weeklySermonSeriesSlug, weeklySermonDate, weeklySermonSpeaker, weeklySermonPassage, weeklySermonVideoId } = data.allContentfulWeeklySermonUpdate.edges[0].node;
        const isSeries = (weeklySermonSeriesName !== "empty");

        return (
          <StyledSermonBackground>
            <SermonsContainer>
              <ServiceDetailContainer>
                <DescriptionDetailTitleMobile>
                  <span style={DescriptionTitleFontMobile}>말씀 다시듣기</span>
                </DescriptionDetailTitleMobile>
                <ServiceDetailVideoContainer>
                  <PlayerWrapper>
                      <Player
                        className='react-player'
                        url={`https://www.youtube.com/embed/${weeklySermonVideoId}?autoplay=0&control=1&color=white`}
                        controls={true}
                        width='100%'
                        height='100%'
                      />
                  </PlayerWrapper>
                </ServiceDetailVideoContainer>
                <DescriptionDetailContainer>
                  <DescriptionDetailTitle>
                    <span style={DescriptionTitleFont}>말씀 다시듣기</span>
                  </DescriptionDetailTitle>
                  <SermonVideoItemContainer>
                    <SermonVideoTitleSpan>{weeklySermonTitle}</SermonVideoTitleSpan>
                    <SermonVideoDescSpan>
                      {weeklySermonDate}
                      <SermonVideoDescDivider>|</SermonVideoDescDivider>
                      {weeklySermonSpeaker}
                    </SermonVideoDescSpan>
                    <SermonVideoDescSpan>{weeklySermonPassage}</SermonVideoDescSpan>
                    {isSeries &&
                      <Fragment>
                        <TextLink primary to={`/` + weeklySermonSeriesSlug}> 
                          <BoldSpan>{weeklySermonSeriesName}</BoldSpan>
                        </TextLink>
                      </Fragment>
                    }
                    <TextLink to={`/sermons-all`}>
                      <BoldSpan>전체보기 {`>`}</BoldSpan>
                    </TextLink>
                  </SermonVideoItemContainer>
                </DescriptionDetailContainer>
              </ServiceDetailContainer>
            </SermonsContainer>
          </StyledSermonBackground>
        )
      }}
    />
  )
}

export default Sermons
