import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const StyledFullScreenWrapper = styled.div`
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: none;
  }
  display: flex;
  width: 100%;
  overflow: hidden;
`
const StyledFullScreenWrapperMobile = styled.div`
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: flex;
  }
  display: none;
  width: 100%;
  overflow: hidden;
`
const SermonBackground = ({ className, children }) => {
  const { sunday_sermon, sunday_sermon_m } = useStaticQuery(
    graphql`
      query {
        sunday_sermon: file(relativePath: { eq: "sunday_sermon.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sunday_sermon_m: file(relativePath: { eq: "sunday_sermon_m.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      
    `
  )
  const imageData = sunday_sermon.childImageSharp.fluid
  const imageDataMobile = sunday_sermon_m.childImageSharp.fluid
  return (
    <>
      <StyledFullScreenWrapper>
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          {children}
        </BackgroundImage>
      </StyledFullScreenWrapper>
      <StyledFullScreenWrapperMobile>
        <BackgroundImage Tag="section" className={className} fluid={imageDataMobile}>
          {children}
        </BackgroundImage>
      </StyledFullScreenWrapperMobile>
    </>
  )
}

const StyledSermonBackground = styled(SermonBackground)`
  display: flex;
  width: 100vw;
  min-height: 40rem;
  /* height: 75vh; */
  /* For mobile and tablet; up to medium */
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    &:after,
    &:before {
      background-size: contain;
    }
    height: 65vh;
  }
  /* For landscape mode; up to medium breakpoint */
  @media screen and (max-width: ${props =>
      props.theme.responsive.medium}) and (orientation: landscape) {
    &:after,
    &:before {
      background-size: contain;
    }
    height: 65vh;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: 55vh;
  }
  @media screen and (min-width: 1800px) {
    height: 40vh;
  }
`
export default StyledSermonBackground
