import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const StyledFullScreenWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`
const BackgroundSection = ({ className, children }) => {
  const { homepageHeroImage } = useStaticQuery(
    graphql`
      query {
        homepageHeroImage: file(relativePath: { eq: "homepageHeroImage.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = homepageHeroImage.childImageSharp.fluid
  return (
    <StyledFullScreenWrapper>
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        {children}
      </BackgroundImage>
    </StyledFullScreenWrapper>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100vw;
  /* height: 75vh; */
  /* For mobile and tablet; up to medium */
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    &:after,
    &:before {
      background-size: auto;
    }
    height: 75vh;
  }
  /* For landscape mode; up to medium breakpoint */
  @media screen and (max-width: ${props =>
      props.theme.responsive.medium}) and (orientation: landscape) {
    &:after,
    &:before {
      background-size: auto;
    }
    min-height: 40rem;
  }
  /* For medium and up */
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    height: 75vh;
  }
`
export default StyledBackgroundSection
