import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import Oval from '../../../static/images/oval.svg'
import OvalFilled from '../../../static/images/oval_filled.svg'
import EventsImage from './EventsImage'

// WIP
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ArrowLeft from '../../../static/images/arrow_left.svg'

const CarouselControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  max-width: 28.5rem;
`
const CustomDotGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
`
const CarouselArrowLeft = styled(ArrowLeft)`
  width: 10px;
  height: 14px;
  cursor: pointer;
`
const CarouselArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
  width: 10px;
  height: 14px;
  cursor: pointer;
`
const StyledDot = styled(Dot)`
  margin: 0 auto;
`

const OuterEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 18rem;
  margin: 0 auto;
  padding-top: 2rem;
  font-size: 18px;
  color: ${props => props.theme.colors.green};
  @media screen and (max-width: 1008px) {
    padding: 1rem 0;
  }
`
const EventContainer = styled.div`
  border: 2px solid ${props => props.theme.colors.green};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 18rem;
  height: 18.25rem;
  margin: 1rem;
  @media screen and (max-width: 1008px) {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    max-width: 28rem;
  }
`
const EventImageContainer = styled.div`
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12rem;
`
const EventTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
`
const TitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.25rem;
  margin-top: -0.25rem;
`
const BoldSpan = styled.span`
  font-weight: 600;
`
const DescSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  line-height: 1.25rem;
  font-size: 90%;
`
const DescSpace = styled.span`
  height: 0.25rem;
  width: 100%;
`

function CarouselWrapper() {
  // Hardcoded for now
  const totalSlidesCount = 2

  return (
    <CarouselProvider
      naturalSlideWidth={300}
      naturalSlideHeight={200}
      totalSlides={totalSlidesCount}
      lockOnWindowScroll
      isPlaying
      interval={7000}
      infinite
      isIntrinsicHeight
      style={{ width: '100%', paddingTop: '0.5rem' }}
    >
      <Global
        styles={css`
          .EventsCarouselDot.carousel__dot--selected > svg:nth-child(2) {
            display: initial;
          }
          .EventsCarouselDot.carousel__dot--selected > svg:nth-child(1) {
            display: none;
          }
          .EventsCarouselDot > svg:nth-child(2) {
            display: none;
          }
        `}
      />
      <Slider style={{ width: '100%', paddingBottom: '1rem' }}>
        <Slide index={0}>
          <OuterEventContainer>
            <DateContainer>
              <BoldSpan>08.26 - 08.27.2022</BoldSpan>
            </DateContainer>
            <EventContainer>
              <EventImageContainer>
                <EventsImage imageFilename="retreat" />
              </EventImageContainer>
              <EventTextContainer>
                <TitleSpan>
                  <BoldSpan>{`목자 리더 수련회`}</BoldSpan>
                </TitleSpan>
                <DescSpace />
                <DescSpan>
                  <BoldSpan>
                    장소: Camp Arroyo <br/>5535 Arroyo Rd. Livermore, CA 94550
                  </BoldSpan>
                </DescSpan>
                <DescSpace />
                <DescSpan>
                  <BoldSpan>문의: doohee.han@joyfulchurch.us</BoldSpan>
                </DescSpan>
                <DescSpace />
              </EventTextContainer>
            </EventContainer>
          </OuterEventContainer>
        </Slide>
        <Slide index={1}>
          <OuterEventContainer>
            <DateContainer>
              <BoldSpan>08.27 - 08.28.2022</BoldSpan>
            </DateContainer>
            <EventContainer>
              <EventImageContainer>
                <EventsImage imageFilename="dawn" />
              </EventImageContainer>
              <EventTextContainer>
                <TitleSpan>
                  <BoldSpan>{`전교인 부흥집회`}</BoldSpan>
                </TitleSpan>
                <DescSpace />
                <DescSpan>
                  <BoldSpan>
                    장소: (SRPC) 12943 Alcosta Blvd. <br />San Ramon CA 94583
                  </BoldSpan>
                </DescSpan>
                <DescSpace />
                <DescSpan>
                  <BoldSpan>문의: doohee.han@joyfulchurch.us</BoldSpan>
                </DescSpan>
                <DescSpace />
              </EventTextContainer>
            </EventContainer>
          </OuterEventContainer>
        </Slide>
      </Slider>
      <CarouselControlContainer>
        <ButtonBack>
          <CarouselArrowLeft />
        </ButtonBack>
        <CustomDotGroupContainer>
          <StyledDot slide={0} className={'EventsCarouselDot'}>
            <Oval />
            <OvalFilled />
          </StyledDot>
          <StyledDot slide={1} className={'EventsCarouselDot'}>
            <Oval />
            <OvalFilled />
          </StyledDot>
        </CustomDotGroupContainer>
        <ButtonNext>
          <CarouselArrowRight />
        </ButtonNext>
      </CarouselControlContainer>
    </CarouselProvider>
  )
}

export default CarouselWrapper
