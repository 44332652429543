import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import IconPrayer from '../../../static/images/prayer.svg'
import IconCoffeeBreak from '../../../static/images/coffee_break.svg'
import FellowshipCarousel from './FellowshipCarousel'

const StyledIconPrayer = styled(IconPrayer)`
  width: 2.75rem;
  height: 2.75rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 2.5rem;
    height: 2.5rem;
  }
`
const StyledIconCoffee = styled(IconCoffeeBreak)`
  width: 2.75rem;
  height: 2.75rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const FellowshipMobileContainer = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  flex-direction: column;
  margin-top: -1rem;
  flex-wrap: initial;
  @media screen and (max-width: 1008px) {
    display: flex;
  }
`
const FellowshipDesktopContainer = styled.div`
  /* border: 1px dotted #ccc; */
  @media screen and (max-width: 1008px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 65rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    margin-top: -1rem;
    flex-wrap: initial;
  }
`
const FellowShipSectionTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 2rem;
  width: 100%;
  max-width: 58rem;
`
const SectionTitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  flex: 1;
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  @media screen and (max-width: 1008px) {
    margin-left: initial;
  }
`
const SectionTextLink = styled(Link)`
  /* border: 1px dotted #ccc; */
  display: flex;
  flex: initial;
  justify-content: center;
  text-decoration: none;
  color: ${props => props.theme.colors.orange};
  font-weight: 600;
  margin-top: 1.25rem;
  @media screen and (max-width: 1008px) {
    font-size: 90%;
  }
`
const FellowshipContainer = styled.div`
  border: 2px solid ${props => props.theme.colors.green};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 28rem;
  height: 16rem;
  margin: 1rem;
  padding: 1.5rem 1rem;
  @media screen and (max-width: 1008px) {
    justify-content: center;
    width: 90%;
    height: 100%;
    padding: 0.75rem;
    padding-top: 1rem;
    margin: initial;
    margin-left: 5%;
  }
`
const FellowshipColumnContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.75rem;
  @media screen and (max-width: 1008px) {
    padding: 0.5rem;
  }
`
const TitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  background: linear-gradient(to top, #fdb51e 40%, transparent 45%);
  word-break: keep-all;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  margin-top: -0.25rem;
`
const BoldSpan = styled.span`
  font-weight: 600;
`
const DescSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  line-height: 1.25rem;
  max-width: 20rem;
  font-size: 90%;
  padding: 0.2rem 0;
`
const DescSpace = styled.span`
  height: 0.5rem;
  width: 100%;
`
const LinkExternal = styled.a`
  display: flex-inline;
  text-decoration: none;
  color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
`


const Services = props => (
  <>
    <FellowshipDesktopContainer>
      <FellowShipSectionTitleContainer>
        <SectionTitleSpan>Joyful 모임</SectionTitleSpan>
      </FellowShipSectionTitleContainer>
      <FellowshipContainer>
        <FellowshipColumnContainer>
          <StyledIconCoffee />
        </FellowshipColumnContainer>
        <FellowshipColumnContainer>
          <TitleSpan>
            <BoldSpan>카이로스 청년부</BoldSpan>
          </TitleSpan>
          <DescSpan>
            <BoldSpan>
              장소: <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">New Life Church (Dublin Campus)<br />6444 Sierra Ct, Dublin, CA 94568</LinkExternal>
            </BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>시간: 매주 금요일 8PM</BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>문의: kairos@joyfulchurch.us</BoldSpan>
          </DescSpan>
          <DescSpan>
            {`죠이플 교회 청년들이 함께 모여 찬양과 말씀을 통해 하나님의 얼굴을 구하고 그 뜻대로 살기로 결단하고 헌신하는 예배의 시간입니다.`}
          </DescSpan>
        </FellowshipColumnContainer>
      </FellowshipContainer>
      <FellowshipContainer>
        <FellowshipColumnContainer>
          <StyledIconPrayer />
        </FellowshipColumnContainer>
        <FellowshipColumnContainer>
          <TitleSpan>
            <BoldSpan>카리스마 대학부</BoldSpan>
          </TitleSpan>
          <DescSpan>
            <BoldSpan>
              장소: <LinkExternal href="https://goo.gl/maps/ChuAVohTKH9mZQAh6" target="_blank">First Presbyterian Church of Berkeley<br />2407 Dana St, Berkeley, CA 94704</LinkExternal>
            </BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>시간: 학기 중 수요일 7PM</BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>문의: karisma@joyfulchurch.us</BoldSpan>
          </DescSpan>
          <DescSpan>
            {`UC Berkeley 내의 대학생 모임으로 어두운 세상을 복음으로 밝히는 그리스도의 일꾼을 양성하는 예배 공동체입니다.`}
          </DescSpan>
          <DescSpace />
        </FellowshipColumnContainer>
      </FellowshipContainer>
      <FellowshipContainer>
        <FellowshipColumnContainer>
          <StyledIconCoffee />
        </FellowshipColumnContainer>
        <FellowshipColumnContainer>
          <TitleSpan>
            <BoldSpan>커피 브레이크</BoldSpan>
          </TitleSpan>
          <DescSpan>
            <BoldSpan>여성팀: 화, 수, 금 10am, 수&목 8pm</BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>남성팀: 화&목 8pm</BoldSpan>
          </DescSpan>
          <DescSpan>
            <BoldSpan>문의: coffeebreak@joyfulchurch.us</BoldSpan>
          </DescSpan>
          <DescSpace />
          <DescSpan>
            {`성경 본문을 중심으로 성경 발견 학습을 통해 성경을 공부하는 소그룹 중심의 말씀 공동체입니다.`}
          </DescSpan>
        </FellowshipColumnContainer>
      </FellowshipContainer>
    </FellowshipDesktopContainer>
    <SectionTextLink topRow to={`/info/calendar`}>{`일정 전체보기 >`}</SectionTextLink>
    <FellowshipMobileContainer>
      <FellowShipSectionTitleContainer>
        <SectionTitleSpan>Joyful 모임</SectionTitleSpan>
      </FellowShipSectionTitleContainer>
      <FellowshipCarousel />
      <SectionTextLink topRow to={`/info/calendar`}>{`일정 전체보기 >`}</SectionTextLink>
    </FellowshipMobileContainer>
  </>
)

export default Services
