import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import CloseSvg from '../../../static/images/close.svg'

const Wrapper = styled.section`
  /* z-index: 1; */
  display: flex;
  flex-flow: column;
  position: relative;
  align-items: center;
  margin-top: ${props => (props.display ? '0' : '-6rem')};
  padding: 1.5rem 3rem;
  background-color: ${props => props.theme.colors.green};
  transition: all 500ms ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding: 1.5rem 0rem;
  }
`
const TextLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 auto;
  order: 1;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 700;
  word-break: keep-all;
  height: 2rem;
  text-align: center;
  line-height: 1.5rem;
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1em;
    height: 1rem;
    margin: 0.5rem auto;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    height: 2rem;
  }
`
const TextDesc = styled.div`
  display: flex;
  flex: 1;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 400;
  word-break: keep-all;
  height: 4rem;
  text-align: center;
  line-height: 1.5rem;
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1em;
    margin: 0.5rem auto;
  }
`
const LinkExternal = styled.a`
  display: flex;
  flex: 1;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: 400;
  word-break: keep-all;
  height: 4rem;
  text-align: center;
  line-height: 1.5rem;
  text-decoration: none;
  padding-top: 1rem;
`
const ResponsiveBrSm = styled.br`
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    display: initial;
  }
`
const ResponsiveBrMd = styled.br`
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`
const ResponsiveBrLg = styled.br`
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: initial;
  }
`
const ResponsiveBrXL = styled.br`
  display: none;
  @media screen and (max-width: 1080px) {
    display: initial;
  }
`
const CloseContainer = styled.div`
  height: 1rem;
  cursor: pointer;
`
const StyledCloseSvg = styled(CloseSvg)`
  position: absolute;
  align-self: center;
  height: 1rem;
  right: 2rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    height: 0.75rem;
    right: 1rem;
    top: 1rem;
  }
`

const StyledImg = styled(Img)`
  display: block;
  min-width: ${props => props.width}em;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		min-width: ${props => props.widthMobile}em;
	}
`

const InfoImage = ({ filename, width,  widthMobile }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          event: file(relativePath: { eq: "special-dec-22.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <StyledImg
          className="InfoImage"
          fluid={data[`${filename}`].childImageSharp.fluid}
          width={width}
          widthMobile={widthMobile}
        />
      )}
    />
  )
}

const Banner = props => {
  const [display, setdisplay] = useState(true)
  const handleClose = () => {
    setdisplay(false)
  }

  const popupStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 'none',
      boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
      marginTop: '20px',
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }

  Modal.setAppElement('#___gatsby');
  return (<></>)
  // return (
  //   <Wrapper display={display}>
  //     {/* <TextDesc>
  //       {`공지: Joyful Foundation 장학금에 대한 안내를 공유해 드립니다. (마감일: 9/18/2022)`}
  //     </TextDesc>
  //     <TextLink to={`/scholarship-2022/`}>{`더보기 >`}</TextLink> */}
  //     <TextDesc>
  //     교회 이전 일정 : 새벽 예배 - 11/1(화),<ResponsiveBrSm /> 주일 예배 - 11/6(주일) <br/><ResponsiveBrXL /> 
  //     6444 Sierra Ct, Dublin, CA 94568
  //     </TextDesc>
  //     <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">{`지도보기 >`}</LinkExternal>
  //     {/* <CloseContainer onClick={handleClose}>
  //       <StyledCloseSvg />
  //     </CloseContainer> */}
  //     <Modal
  //       onClick={closeModal}
  //       isOpen={modalIsOpen}
  //       onRequestClose={closeModal}
  //       style={popupStyles}
  //       contentLabel="Popup Banner"
  //     >
  //       <InfoImage onClick={closeModal} filename={"event"} width={24} widthMobile={18} />
  //     </Modal>
  //   </Wrapper>
  // )
}

export default Banner
