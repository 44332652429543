import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import EventsCarousel from './EventsCarousel'
import EventsImage from './EventsImage'

const EventsMobileContainer = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  flex-direction: column;
  margin-top: -1rem;
  flex-wrap: initial;
  @media screen and (max-width: 1008px) {
    display: flex;
  }
`
const EventsDesktopContainer = styled.div`
  /* border: 1px dotted #ccc; */
  @media screen and (max-width: 1008px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 65rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    margin-top: -1rem;
    flex-wrap: initial;
  }
`
const EventsSectionTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 58rem;
`
const SectionTitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  flex: 1;
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  @media screen and (max-width: 1008px) {
    margin-left: initial;
  }
`
const SectionTextLink = styled(Link)`
  /* border: 1px dotted #ccc; */
  display: flex;
  width: 100%;
  justify-content: center;
  flex: initial;
  text-decoration: none;
  color: ${props => props.theme.colors.orange};
  font-weight: 600;
  margin-top: 2rem;
  @media screen and (max-width: 1008px) {
    font-size: 90%;
    margin-top: 1.25rem;
  }
`
const OuterEventContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 18rem;
  margin: 0 auto;
  padding-top: 2rem;
  font-size: 18px;
  color: ${props => props.theme.colors.green};
`
const EventContainer = styled.div`
  border: 2px solid ${props => props.theme.colors.green};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 18rem;
  height: 18.25rem;
  margin: 1rem;
  @media screen and (max-width: 1008px) {
    justify-content: center;
    width: 90%;
    height: 100%;
    padding: 0.75rem;
    padding-top: 1rem;
    margin: initial;
    margin-left: 5%;
  }
`
const EventImageContainer = styled.div`
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
`
const EventTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
`
const TitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.25rem;
  margin-top: -0.25rem;
`
const BoldSpan = styled.span`
  font-weight: 600;
`
const DescSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  line-height: 1.25rem;
  max-width: 20rem;
  font-size: 90%;
`
const DescSpace = styled.span`
  height: 0.25rem;
  width: 100%;
`
const SectionDivider = styled.hr`
  width: 90vw;
  max-width: 58rem;
  margin-bottom: 3.5rem;
  border-top: 1px solid #f3f3f3;
  border-radius: 1px;
  margin-top: -1rem;
  @media screen and (max-width: 1008px) {
    max-width: 28rem;
  }
`

const Events = props => (
  <>
    <SectionDivider />
    <EventsDesktopContainer>
      <EventsSectionTitleContainer>
        <SectionTitleSpan>다가오는 이벤트</SectionTitleSpan>
      </EventsSectionTitleContainer>
      <OuterEventContainer>
        <DateContainer>
          <BoldSpan>08.26 - 08.27.2022</BoldSpan>
        </DateContainer>
        <EventContainer>
          <EventImageContainer>
            <EventsImage imageFilename="retreat" />
          </EventImageContainer>
          <EventTextContainer>
            <TitleSpan>
              <BoldSpan>{`목자 리더 수련회`}</BoldSpan>
            </TitleSpan>
            <DescSpace />
            <DescSpan>
              <BoldSpan>
                장소: Camp Arroyo <br/>5535 Arroyo Rd.<br/>Livermore, CA 94550
              </BoldSpan>
            </DescSpan>
            <DescSpace />
            <DescSpan>
              <BoldSpan>문의: doohee.han@joyfulchurch.us</BoldSpan>
            </DescSpan>
            <DescSpace />
          </EventTextContainer>
        </EventContainer>
      </OuterEventContainer>
      <OuterEventContainer>
        <DateContainer>
          <BoldSpan>08.27 - 08.28.2022</BoldSpan>
        </DateContainer>
        <EventContainer>
          <EventImageContainer>
            <EventsImage imageFilename="dawn" />
          </EventImageContainer>
          <EventTextContainer>
            <TitleSpan>
              <BoldSpan>전교인 부흥집회</BoldSpan>
            </TitleSpan>
            <DescSpace />
            <DescSpan>
              <BoldSpan>장소: SRPC<br />
              12943 Alcosta Blvd<br />San Ramon CA 94583</BoldSpan>
            </DescSpan>
            <DescSpace />
            <DescSpan>
              <BoldSpan>문의: doohee.han@joyfulchurch.us</BoldSpan>
            </DescSpan>
            <DescSpace />
            {/* <DescSpan>
             {`목자목녀 수련회가 8월로 연기되었습니다. 자세한 내용은 추후 공지 내용을 확인 부탁드립니다.`}
            </DescSpan> */}
            <DescSpace />
          </EventTextContainer>
        </EventContainer>
      </OuterEventContainer>
      <SectionTextLink to={`/info/calendar#events`} topRow>{`이벤트 전체보기 >`}</SectionTextLink>
    </EventsDesktopContainer>
    <EventsMobileContainer>
      <EventsSectionTitleContainer>
        <SectionTitleSpan>다가오는 이벤트</SectionTitleSpan>
      </EventsSectionTitleContainer>
      <EventsCarousel />
      <SectionTextLink to={`/info/calendar#events`} topRow>{`이벤트 전체보기 >`}</SectionTextLink>
    </EventsMobileContainer>
  </>
)

export default Events
